@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import 'datepicker.scss';

html, body, #app {
    font-family: 'Nunito', sans-serif;
    @apply min-h-screen
}

a {
    @apply text-black
}

a:hover {
    @apply text-sbr-orange
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
 }

 .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 }

 .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
 }

 .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 5px 10px 4px;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;

      .popover-inner {
        background: $color;
        color: black;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, .1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }

.btn {
    @apply font-bold py-2 px-4 rounded mr-4 shadow
}

.btn-primary {
    &[disabled] {
        @apply bg-gray-500 text-white #{!important}
    }

    &[hover] {
        @apply bg-blue-700
    }

    @apply bg-blue-500 text-white
}

.btn-alert {
    @apply bg-red-500 text-white
}

.btn-alert:hover {
    @apply bg-red-700
}

.btn-secondary {
    @apply bg-white text-gray-800 font-bold py-2 px-4 border border-gray-400
}

.btn-secondary:hover {
    @apply bg-gray-300
}

.btn-small {
    @apply bg-blue-500 text-white font-bold p-1 text-xs cursor-pointer rounded mr-2 shadow
}


.btn-small-green {
    @apply bg-green-500 text-white font-bold p-1 text-xs cursor-pointer rounded mr-2 shadow
}

.btn-primary:hover {
    @apply bg-blue-700
}


.listing {
    th {
        @apply py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300
    }

    tr {
        &:hover {
            @apply bg-gray-200
        }
    }

    @apply text-left w-full border-collapse
}

.import {
    th {
        @apply py-2 px-2 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300 whitespace-nowrap
    }

    tr {
        &:hover {
            @apply bg-gray-200
        }

        td.error {
            @apply bg-red-300
        }
    }

    @apply text-left w-full border-collapse
}

.pagination {
    @apply flex w-auto font-sans justify-center
}

.pagination {
    a {
        cursor: pointer;
        padding: 8px;
    }
}

.pagination-inactive {
    opacity: 0.5 !important;
    a {
        opacity: 0.4;
        cursor: default;
    }
}

.pagination-page,
.pagination-next,
.pagination-prev {
    &.active {
        @apply font-bold text-sbr-orange
    }

    &:hover {
        @apply text-sbr-orange
    }

    @apply block text-black px-3 py-2 border border-gray-400 bg-white
}

.pagination-inactive {
    @apply text-black
}

.mx-datepicker,
.mx-datepicker-range {
    width: 100% !important
}

.multiselect__tags {
    @apply appearance-none block w-full bg-white text-gray-700 border border-gray-400 pt-2 px-4 mb-3 #{!important}

}

.multiselect__select {
    @apply leading-normal #{!important}
}

.multiselect__input {
    @apply bg-gray-200 text-gray-700 text-base #{!important}
}

.multiselect__placeholder {
    @apply pl-2 pt-0 mb-2 text-gray-400 text-base #{!important}
}

.multiselect__input::placeholder {
    @apply pl-2 pt-2 text-gray-500 text-base #{!important}
}

.multiselect__single {
    @apply pl-2 bg-white text-gray-700 text-base #{!important}
}

.multiselect__spinner {
    @apply bg-gray-200 #{!important}
}

.palette-label {
    @apply mx-3 mb-6 w-full items-center px-2 py-2 bg-white text-sbr-purple rounded-lg shadow-lg tracking-wide border border-sbr-purple cursor-pointer
}

.palette-label:hover {
    @apply text-sbr-orange
}
